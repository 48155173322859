// @flow

import React from 'react'

type Props = {
  children: React$Node,
}

const Layout = ({ children }: Props) => {
  return (
    <>
      <div>
        <main>{children}</main>
      </div>
    </>
  )
}

export default Layout
